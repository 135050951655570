// =============================================================================
// Colors
// =============================================================================

export const RED = '#EB3742';
export const YELLOW = '#FFDC62';
export const GREEN = '#21E56F';
export const BLUE = '#59cff7';
export const PURPLE = '#8A81F8';
export const WHITE = '#FFFFFF';
export const GRAY = '#777777';
export const REACT_GRAY = '#222222';
export const DARK_GRAY = '#333333';
export const LIGHT_GRAY = '#444444';
export const BLACK = '#000000';


// ========================
// Paths
// ========================

export const lh = 'http://localhost';
export const ph = 'https://solana-phantom.ratersapp.com/backend';
export const host = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? lh : ph;